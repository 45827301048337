<script setup lang="ts">
</script>
<template>
  <div
    class="features"
    id="features"
  >
    <div class="features-content">
      <div class="features-content-text">
        <h1 class="features-content-text-title">Особенности <br>таргетированной <br>рекламы</h1>
        <p class="features-content-text-description">
          Соцсети – настоящий клад для рекламодателей, ведь эти
          сайты посещают миллионы людей, которые любят делиться
          информацией о себе.
        </p>
        <p class="features-content-text-description">
          Это значит, что благодаря анкетным данным можно легко
        вычислить представителей вашей целевой аудитории, которые
        действительно заинтересованы в вашем рекламном
        предложении.
        </p>
        <p class="features-content-text-description">
          Показывая свои объявления только им, вы в несколько раз
        быстрее достигнете своей цели.
        </p>
      </div>
      <div class="features-content-left">
        <img
          class="features-content-left-image"
          src="@/assets/images/Artboard.webp"
          alt="">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.features {
  margin-top: get-vw(400px);
  @media (orientation: landscape){
    margin-top: get-vw(200px);
  }
  &-content {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: get-vw(70px);
    @media (orientation: landscape){
      width: 65%;
    }
    &-left {
      width: 100%;
      &-image {
        //margin-bottom: get-vw(-80px);
        margin-left: -5%;
        height: 110%;
        width: 110%;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      position: relative;
      &-title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: get-vw(150px);
        line-height: 85.52%;
        color: #000000;
        position: relative;
        margin-bottom: get-vw(150px);
        @media (orientation: landscape){
          font-size: get-vw(110px);
          margin-bottom: get-vw(50px);
        }
      }
      &-description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: get-vw(65px);
        line-height: get-vw(90px);
        color: #000000;
        margin-top: get-vw(70px);
        @media (orientation: landscape){
          font-size: get-vw(40px);
          line-height: get-vw(60px);
          margin-top: get-vw(30px);
        }
      }
    }
    &-text::before {
      content: '';
      display: block;
      position: absolute;
      bottom: get-vw(-80px);
      width: 55%;
      border-bottom: get-vw(10px) solid #4C4EF9;
    }
  }
}
</style>
